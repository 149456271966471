<template>
    <div class="maincontent">
        <div class="container">
            <div class="row">
                <div class="col-12 headerPic" style="position:relative;height:500px">
                    <!-- Vueper Slide paralax einfügen -->
                    <vueper-slides ref="myVueperSlides" fixedHeight=600px :parallax="-1" :parallax-fixed-content="false">
                        <vueper-slide
                            v-for="(slide, i) in slides" :key="i"
                            :image="require(`@/assets/${slide.image}`)" 
                            :style="'min-height: 100%'"/>
                        </vueper-slides>
                    <!-- <img style="max-width:100%" src="@/assets/JVS90_Perspektiven.jpg" alt="">
                    <span class="text-uppercase leftCorner"> Julius-vossler-straße 90</span> -->
                    <div class="card vermietenCard darkBG textSize">
                        <div class="card-header">
                            Alle Wohnungen zur Zeit vermietet
                        </div>
                        <div class="card-body">
                        <!-- <b-button @click="goToRoute({name:'blueprintFlat'})" variant="danger" style="font-size:1.5rem">ZU DEN WOHNUNGEN</b-button> -->
                            Alle Tiefgaragenstellplätze zur Zeit vermieten
                        </div>
                    </div>
                </div>
            </div>
            <div class="row m-lg-0">
                <div class="col-12 darkBG">
                    <div class="row my-lg-2 textSize">
                        <div class="col-12">
                           <span class="text-uppercase"> Hier entstehen 32 komfortable 1-4 Zimmer-Mietwohnungen mit Tiefgaragenstellplatz</span> 
                        </div>
                    </div>
                    <div class="row stuff">
                        <div class="col-12 col-md-4 mt-4 mt-md-0">
                            <div class="text-left w-50" style="left:50%; position:relative">
                                <p class="text-uppercase">Bauherr/ Vermietung </p>
                                <p>Immobilienverwaltung Thiemecke GbR</p>
                            </div>
                            <div class="text-left w-75" style="left:15%; position:relative">
                                <h2> <a href="https://www.jv-living.de" target="blank">www.jv-living.de</a></h2>
                            </div>
                        </div>
                        <div class="col-6 col-md-2 text-right mt-4 mt-md-0">
                            <img class="jMLogo" src="@/assets/jepsen_michaelis_logo.png" alt="">
                        </div>
                        <div class="col-6 col-md-2 text-left mt-4 mt-md-0">
                            <p class="text-uppercase">Architekten </p>
                            <p>Jepsen + Michaelis Architekten mbB</p>
                            <p>(Partnerschaft mbB)</p>
                            <p>Bahrenfelder Strasse 73d</p>
                            <p>22765 Hamburg</p>
                            <a href="http://www.jepsenmichaelis.de" target="blank">www.jepsenmichaelis.de</a>
                        </div>
                        <div class="col-6 col-md-2 text-right mt-4 mt-md-0">
                            <img class="APLogo" src="@/assets/aug.prien_logo.jpeg" alt="">
                        </div>
                        <div class="col-6 col-md-2 text-left mt-4 mt-md-0">
                            <p class="text-uppercase">Generalunternehmen </p>
                            <p>Aug. Prien Bauunternehmen</p>
                            <p>(GmbH + Co. KG)</p>
                            <p>Dampfschiffsweg 3-9</p>
                            <p>21079 Hamburg</p>
                            <a href="https://www.augprien.de" target="blank">www.augprien.de</a>
                        </div>
                    </div>
                    <div class="row" style="color:#fff; margin-top:4rem">
                        <div class="col-6 col-md-2 text-right mt-4 mt-md-0">
                            <img class="ktcLogo" src="@/assets/ktc_logo.png" alt="">
                        </div>
                        <div class="col-6 col-md-2 text-left mt-4 mt-md-0">
                            <p class="text-uppercase">Tragwerksplanung </p>
                            <p>KTC Ingenieursgesellschaft</p>
                            <p>(GmbH + Co. KG)</p>
                            <p>Kastanienweg 20</p>
                            <p>27404 Zeven</p>
                            <a href="http://www.ktc-ingenieure.de" target="blank">www.ktc-ingenieure.de</a>
                        </div>
                        <div class="col-6 col-md-2 text-right mt-4 mt-md-0">
                            <img class="ibeLogo" src="@/assets/IBE_logo.png" alt="">
                        </div>
                        <div class="col-6 col-md-2 text-left mt-4 mt-md-0">
                            <p class="text-uppercase">Technische Gebäudeausrüstung </p>
                            <p>IBE Ingenieurbüro Eilf</p>
                            <p>(GmbH + Co. KG)</p>
                            <p>Richard-Wagner-Strasse 4</p>
                            <p>23556 Lübeck</p>
                            <a href="http://www.ib-eilf.de" target="blank">www.ib-eilf.de</a>
                        </div>
                        <div class="col-6 col-md-2 text-right mt-4 mt-md-0">
                            <img class="spkLogo" src="@/assets/sk_altes_land_logo.png" alt="">
                        </div>
                        <div class="col-6 col-md-2 text-left mt-4 mt-md-0">
                            <p class="text-uppercase">Finanzierungspartner </p>
                            <p>Sparkasse Stade-Altes-Land</p>
                            <p>Pferdemarkt 11a</p>
                            <p>21682 Stade</p>
                            <a href="https://www.spk-sal.de" target="blank">www.spk-sal.de</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'Home',
  components: {
    VueperSlides, VueperSlide
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
            slides:[
                {
                    image:"JVS90_Perspektiven.jpg",
                    title: 'Slide 1',
                    content: 'Slide 1 content.'
                }
            ]
        }
    },
    methods:{
        goToRoute(params){
            this.$router.push(params)
        },
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
.vermietenCard{
    position:absolute;
    top: 15%;
    left: 7%;
    z-index: 300;
}
a{
    color:#fff;
}
.leftCorner{
    position: absolute;
    bottom: 0.5rem;
    left: 4.5rem;
    color:#fff;
    /* font-family: serif; */
    font-size: 1.7rem;
    font-weight: 300;
    letter-spacing: 0.25rem;
}
.jMLogo{
    max-width: 11rem;
}
.APLogo{
    max-width: 9rem;
}
.textSize{
    font-size:1.7rem; 
    font-weight:300; 
    color:#fff; 
    letter-spacing:0.1rem
}
@media(max-width:576px){
    .textSize{
        font-size:1rem; 
        font-weight:300; 
        color:#fff; 
        letter-spacing:0.1rem;
        margin-top: 125px;
    }
    .stuff{
        margin-top:0.5rem !important
    }
    .headerPic{
        padding: 0 !important
    }
}
.headerPic{
    padding-left: 15px;
    padding-right: 15px;
}
.stuff{
    color:#fff; 
    margin-top:4rem
}
.ktcLogo{
    max-width: 9rem;
}
.ibeLogo{
    max-width: 9rem;
}
.spkLogo{
    max-width: 9rem;
}
.darkBG{
    background-color: #343434;
}
.container {
  position: relative;
  text-align: center;
}
</style>